import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { registerCall } from '../utils/User';
import{ Formik } from 'formik';
import * as yup from 'yup';
import uninovalogo from "./uninova.png";
import vesselailogo from "./Vessel Logo.png"


import "./LoginPage.css"

const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
    password2: yup.string().required()
});


function RegisterPage() {

    const navigate = useNavigate();

    const registerSubmit = (values) => {
        if (values.password !== values.password2) {
            console.log("Passwords do not match");
        } else {
            registerCall(values.email, values.password, values.first_name, values.last_name);
            navigate("/");
        }
    }

    return (
        <div className='bg-gradient-primary'>
            <Container className='login-container'>
                <Row className="justify-content-center">
                    <Col xl={5} lg={12} md={9} className='column-style'>
                        <Card className="o-hidden border-0 shadow-lg my-5">
                            <Card.Body className="p-0">
                                <div className='login-header'>
                                    <Image className='p-0 i4qlogo' fluid id='i4qlogo' src={vesselailogo} alt="vesselailogo" />
                                </div>
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={registerSubmit}
                                    initialValues={{
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        password: '',
                                        password2: ''
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                        <Form className="p-5" noValidate onSubmit={handleSubmit}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                                    <Form.Control
                                                        className="lh-lg"
                                                        type="text"
                                                        placeholder="First name"
                                                        value={values.first_name}
                                                        name="first_name"
                                                        onChange={handleChange}
                                                        isValid={touched.first_name && !errors.first_name}
                                                        isInvalid={!!errors.first_name}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        First Name required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                                    <Form.Control
                                                        required
                                                        className="lh-lg"
                                                        type="text"
                                                        placeholder="Last name"
                                                        value={values.last_name}
                                                        name="last_name"
                                                        onChange={handleChange}
                                                        isValid={touched.last_name && !errors.last_name}
                                                        isInvalid={!!errors.last_name}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        Last Name required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control
                                                    className="lh-lg"
                                                    type="email"
                                                    placeholder="Enter email address..."
                                                    value={values.email}
                                                    name="email"
                                                    onChange={handleChange}
                                                    isValid={touched.email && !errors.email}
                                                    isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Email required
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="6" className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control
                                                        className="lh-lg"
                                                        type="password"
                                                        placeholder="Password"
                                                        value={values.password}
                                                        name="password"
                                                        onChange={handleChange}
                                                        isValid={touched.password && !errors.password}
                                                        isInvalid={!!errors.password}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        Password required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6" className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control
                                                        className="lh-lg"
                                                        type="password"
                                                        placeholder="Repeat Password"
                                                        value={values.password2}
                                                        name="password2"
                                                        onChange={handleChange}
                                                        isValid={touched.password2 && !errors.password2}
                                                        isInvalid={!!errors.password2}
                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        Password required
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Button type="submit" variant="primary" className="btn-block">
                                                Register Account
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </Card.Body>
                            <Card.Footer>
                                <div className='text-center'>
                                    Already have an account? <a href='/'>Login</a>
                                </div>
                            </Card.Footer>
                            <Image className='uninova-logo' src={uninovalogo} alt="uninovalogo" />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default RegisterPage;
