import { useSearchParams } from 'react-router-dom'
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import { resetPasswordCall } from '../utils/User';
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import * as yup from 'yup';

import uninovalogo from "./uninova.png";
import vesselailogo from "./Vessel Logo.png"


const schema = yup.object().shape({
    password: yup.string().required(),
    password2: yup.string().required()
});


function ResetPasswordPage(){

    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const email = searchParams.get('user_email');

    const resetSubmit = (values) => {
        if (values.password !== values.password2) {
            console.log("Passwords do not match");
        } else {
            resetPasswordCall(email, values.password);
            navigate("/");
        }
    }


    return (
        <div className='bg-gradient-primary'>
            <Container className='login-container'>
                <Row className="justify-content-center">
                    <Col xl={10} lg={12} md={9} className='column-style'>
                        <Card className="o-hidden border-0 shadow-lg my-5">
                            <Card.Body className="p-0">
                                <Row>
                                    <Col lg={5} className='d-none d-lg-block text-center intro'>
                                        <Image className='p-5' fluid id='uninova-logo' src={uninovalogo} alt="uninovalogo" />
                                        <Row>
                                            <Col lg={3}>
                                            </Col>

                                            <Col lg={6}>
                                                <Image className='p-5' fluid id='i4qlogo' src={vesselailogo} alt="vesselailogo" />
                                            </Col>
                                            <Col lg={3}>
                                                {/* <Image className='p-5' fluid id='vesselailogo' src={vesselailogo} alt="vesselailogo" /> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={7}>
                                        <div className='text-center'>
                                            <h1 className='h4 text-gray-900 mt-4'>Welcome!</h1>
                                        </div>
                                        <Formik
                                            validationSchema={schema}
                                            onSubmit={resetSubmit}
                                            initialValues={{
                                                password: '',
                                                password2: ''
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                values,
                                                errors,
                                            }) => (
                                                <Form className="p-3" noValidate onSubmit={handleSubmit}>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Control
                                                            className="lh-lg"
                                                            type="password"
                                                            placeholder="Password"
                                                            value={values.password}
                                                            name="password"
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password2}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Password required
                                                        </Form.Control.Feedback>

                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Form.Control
                                                            className="lh-lg"
                                                            type="password"
                                                            placeholder="Repeat Password"
                                                            value={values.password2}
                                                            name="password2"
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password2}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Password required
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Button type="submit" variant="outline-primary" className="btn-block">
                                                        Reset Password
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                        <hr></hr>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ResetPasswordPage;
