import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { forgotPasswordCall } from '../utils/User';
import uninovalogo from "./uninova.png";
import { Formik } from 'formik';
import * as yup from 'yup';
import vesselailogo from "./Vessel Logo.png"


import "./LoginPage.css"

const schema = yup.object().shape({
    email: yup.string().required()
});

function ForgotPasswordPage() {

    const navigate = useNavigate();

    const forgotSubmit = (values) => {
        forgotPasswordCall(values.email);
        navigate("/");
    }


    return (
        <div className='bg-gradient-primary'>
        <Container className='login-container'>
            <Row className="justify-content-center">
                <Col xl={5} lg={12} md={9} className='column-style'>
                    <Card className="o-hidden border-0 shadow-lg my-5">
                        <Card.Body className="p-0">
                            <div className='login-header'>
                                <Image className='p-0 i4qlogo' fluid id='i4qlogo' src={vesselailogo} alt="vesselailogo" />
                            </div>
                            <Formik
                                validationSchema={schema}
                                onSubmit={forgotSubmit}
                                initialValues={{
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    password: '',
                                    password2: ''
                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    values,
                                    errors,
                                }) => (
                                    <Form className="p-5" noValidate onSubmit={handleSubmit}>
                                        <div className='text-center'>
                                            <p className="mb-4 mx-4">We get it, stuff happens. Just enter your email address below and we'll send you a link to reset your password!</p>
                                        </div>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control
                                                required
                                                className="lh-lg"
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                placeholder="Enter email address..."
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Email required
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button type="submit" variant="primary" className="btn-block">
                                            Reset Password
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                        <Card.Footer>
                            <div className='text-center'>
                                Already have an account? <a href='/'>Login</a>
                            </div>
                            <div className='text-center'>
                                If you do not have account? <a href='/register'>Register</a>
                            </div>
                        </Card.Footer>
                        <Image className='uninova-logo' src={uninovalogo} alt="uninovalogo" />
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
);
}

export default ForgotPasswordPage;
