import { useState } from 'react';
import { loginCall } from '../utils/User'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import { useAuth } from '../context/AuthProvider';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";

import uninovalogo from "./uninova.png";
import vesselailogo from "./Vessel Logo.png"

import "./LoginPage.css"

const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required()
});


function LoginPage() {

    const { login } = useAuth();


    const loginSubmit = async (values) => {
        let response = await loginCall(values.email, values.password);
        let data = await response.json();
        if (data["error"] === undefined) {
            login({ token: data, email: values.email });
        } else {
            console.log("Error", data["error"], data["error_msg"]);
        }
        // setLoginForm({
        //     email: '',
        //     password: '',
        // })
    }


    return (
        <div className='bg-gradient-primary'>
            <Container className='login-container'>
                <Row className="justify-content-center">
                    <Col xl={5} lg={12} md={9} className='column-style'>
                        <Card className="o-hidden border-0 shadow-lg my-5">
                            <Card.Body className="p-0">
                                <div className='login-header'>
                                    <Image className='p-0 i4qlogo' fluid id='i4qlogo' src={vesselailogo} alt="vesselailogo" />
                                </div>
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={loginSubmit}
                                    initialValues={{
                                        email: '',
                                        password: ''
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        errors,
                                    }) => (
                                        <Form className="p-5 login-box" noValidate onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3"  controlId="validationFormik01">
                                                <Form.Control
                                                    className="lh-lg"
                                                    type="email"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder="Enter email address..."
                                                    // isValid={touched.firstName && !errors.firstName}
                                                    isInvalid={!!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Email required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="validationFormik02">
                                                <Form.Control
                                                    className="lh-lg"
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    placeholder="Password"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Password required
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <div className='forgot-password'>
                                                Forgot <a href='/forgot_password'>Password</a> ?
                                            </div>
                                            <Button type="submit" variant="primary" className="btn-block">
                                                Login
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </Card.Body>
                            <Card.Footer>
                                <div className='text-center'>
                                    If you do not have account? <a href='/register'>Register</a>
                                </div>
                            </Card.Footer>
                            <Image className='uninova-logo' src={uninovalogo} alt="uninovalogo" />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default LoginPage;
