import { createElementId } from "./General";
import { wf_list } from "./Global";
import { apiHost, apiPort } from "./Global";

const apiConnectionString = `${apiHost}:${apiPort}`;

async function checkWorkflowIdExists(name) {
    let wf_id = createElementId(name);
    const response = await fetch(apiConnectionString + "/workflow_exists/" + wf_id);
    const data = await response.json();

    if(data.result){
        return true;
    }else if(wf_list.length > 0){
        let obj = {};
        obj = wf_list.some(o => o.id === wf_id);
        // console.log("Objecto ", obj);
        return obj
    }else{
        return false;
    }   
}

function deleteWorkflowFromList(name){
    function compareWfId(wf){
        return wf_id === wf.id;
    }
    
    var wf_id = createElementId(name);
    var index = wf_list.findIndex(compareWfId);
    wf_list.splice(index, 1);
}

async function saveWorkflowCall(wf) {
    // console.log("wf", wf);
    const response = await fetch(apiConnectionString + "/save_workflow", {
        method: 'POST',
        body: JSON.stringify(wf),
        headers: { 'Content-Type': 'application/json' },
    });
    return await response.json();
}

async function initWorkflowCall(wf, email) {
    const response = await fetch(apiConnectionString + "/init_workflow?" + new URLSearchParams({
        user: email
    }), 
    {
        method: 'POST',
        body: JSON.stringify(wf),
        headers: { 'Content-Type': 'application/json' },
    });
    return await response.json();
}

async function getWorkflowListCall(email) {
    const response = await fetch(apiConnectionString + "/list_workflows?" + new URLSearchParams({
        user: email 
    }),{
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },

    });
    let data = await response.json();

    return data
}

async function getWorkflowObj(id) {

    const response = await fetch(apiConnectionString + "/get_workflow/" + id, {
        method: 'GET', 
        headers: { 'Content-Type': 'application/json' },
    });
    let data = await response.json();

    return data
}


export {checkWorkflowIdExists, saveWorkflowCall, initWorkflowCall, getWorkflowListCall, getWorkflowObj, deleteWorkflowFromList};